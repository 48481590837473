import React, { useRef } from 'react'
import { mailchimpCountries, mailchimpBusinessTypes } from '../utils/utils'
import { navigate } from 'gatsby'

const MailChimpFormEmbedded = () => {
  const formElRef = useRef(null)
  return (
    <div id="mc_embed_signup" ref={formElRef}>
      <form
        action="https://maskofwellness.us4.list-manage.com/subscribe/post?u=66a2d815c0886c682e4afc276&amp;id=055b1e3643"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        novalidate
      >
        <div id="mc_embed_signup">
          <div id="mc_embed_signup_scroll">
            <h2 className="has-text-centered">Self-Certification</h2>
            <h5 className="has-text-centered">
              For 'Covid-Conscious' Businesses
            </h5>
            {/*<div className="indicates-required">
              <span className="asterisk">*</span> indicates required
            </div>*/}

            <div className="field-body">
              <div className="field">
                <label className="label sr-only" htmlFor="mce-FNAME">
                  First Name{' '}
                </label>
                <div className="control">
                  <input
                    type="text"
                    defaultValue=""
                    name="FNAME"
                    className="input"
                    id="mce-FNAME"
                    placeholder="First Name"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label sr-only" htmlFor="mce-LNAME">
                  Last Name{' '}
                </label>
                <div className="control">
                  <input
                    type="text"
                    defaultValue=""
                    name="LNAME"
                    className="input"
                    id="mce-LNAME"
                    placeholder="Last Name"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="field-body">
              <div className="field">
                <label className="label sr-only" htmlFor="mce-BIZNAME">
                  Business Name{' '}
                </label>
                <div className="control">
                  <input
                    type="text"
                    defaultValue=""
                    name="BIZNAME"
                    className="input"
                    id="mce-BIZNAME"
                    placeholder="Business Name"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="field-body">
              <div className="field">
                <label className="label sr-only" htmlFor="mce-BIZWEBSITE">
                  Website{' '}
                </label>
                <div className="control">
                  <input
                    type="url"
                    defaultValue=""
                    name="BIZWEBSITE"
                    className="input"
                    id="mce-BIZWEBSITE"
                    placeholder="Website (https://website.com)"
                  />
                </div>
              </div>
            </div>
            <div className="field-body">
              <div className="field">
                <label className="label sr-only" htmlFor="mce-BIZADDR-addr1">
                  Business Physical Address{' '}
                </label>
                <div className="control">
                  <input
                    type="text"
                    defaultValue=""
                    maxLength="70"
                    name="BIZADDR[addr1]"
                    id="mce-BIZADDR-addr1"
                    className="input"
                    placeholder="Business Physical Address"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label sr-only" htmlFor="mce-BIZADDR-addr2">
                  Address Line 2
                </label>
                <div className="control">
                  <input
                    type="text"
                    defaultValue=""
                    maxLength="70"
                    name="BIZADDR[addr2]"
                    id="mce-BIZADDR-addr2"
                    className="input"
                    placeholder="Address Line 2"
                  />
                </div>
              </div>
            </div>

            <div className="field-body">
              <div className="field">
                <label className="label sr-only" htmlFor="mce-BIZADDR-city">
                  City
                </label>
                <div className="control">
                  <input
                    type="text"
                    defaultValue=""
                    maxLength="40"
                    name="BIZADDR[city]"
                    id="mce-BIZADDR-city"
                    className="input"
                    placeholder="City"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label sr-only" htmlFor="mce-BIZADDR-state">
                  State/Province/Region
                </label>
                <div className="control">
                  <input
                    type="text"
                    defaultValue=""
                    maxLength="20"
                    name="BIZADDR[state]"
                    id="mce-BIZADDR-state"
                    className="input"
                    placeholder="State/Province/Region"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label sr-only" htmlFor="mce-BIZADDR-zip">
                    Postal / Zip Code
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      defaultValue=""
                      maxLength="10"
                      name="BIZADDR[zip]"
                      id="mce-BIZADDR-zip"
                      className="input"
                      placeholder="Zip Code"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label
                    className="label sr-only"
                    htmlFor="mce-BIZADDR-country"
                  >
                    Country
                  </label>
                  <select
                    name="BIZADDR[country]"
                    id="mce-BIZADDR-country"
                    defaultValue=""
                    className="input"
                    placeholder="Country"
                    required
                  >
                    {mailchimpCountries.map(x => (
                      <option key={x.id} value={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label sr-only" htmlFor="mce-PHONE">
                    Phone Number{' '}
                  </label>
                  <div className="control">
                    <input
                      type="tel"
                      name="PHONE"
                      className="input"
                      defaultValue=""
                      id="mce-PHONE"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      placeholder="Phone (xxx-xxx-xxxx)"
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label sr-only" htmlFor="mce-EMAIL">
                    Email Address <span className="asterisk">*</span>
                  </label>
                  <div className="control">
                    <input
                      type="email"
                      defaultValue=""
                      name="EMAIL"
                      className="input"
                      id="mce-EMAIL"
                      placeholder="Email Address"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>



            <div className="field">
              <label className="label sr-only" htmlFor="mce-BIZTYPE">
                Business Type{' '}
              </label>
              <select
                name="BIZTYPE"
                className="input"
                id="mce-BIZTYPE"
                required
                defaultValue=""
              >
                <option value="" disabled>Select A Type</option>
                {mailchimpBusinessTypes.map(x => (
                  <option key={x.id} value={x.id}>
                    {x.id}
                  </option>
                ))}
              </select>
            </div>

            <div className="columns">
              <div className="column">
                  <strong>Services Available</strong>
                  <ul className="list-style-none">
                    <li>
                      <input type="checkbox" value="2" name="group[70344][2]" id="mce-group[70344]-70344-0" />
                        <label for="mce-group[70344]-70344-0">In-store shopping</label>
                    </li>
                    <li>
                      <input type="checkbox" value="4" name="group[70344][4]" id="mce-group[70344]-70344-1" />
                        <label for="mce-group[70344]-70344-1">In-store pickup</label>
                    </li>
                    <li>
                      <input type="checkbox" value="8" name="group[70344][8]" id="mce-group[70344]-70344-2" />
                      <label for="mce-group[70344]-70344-2">Curbside pickup</label>
                    </li>
                    <li>
                    <input type="checkbox" value="16" name="group[70344][16]" id="mce-group[70344]-70344-3" />
                      <label for="mce-group[70344]-70344-3">Delivery</label>
                    </li>
                    <li>
                    <input type="checkbox" value="32" name="group[70344][32]" id="mce-group[70344]-70344-4" />
                      <label for="mce-group[70344]-70344-4">Online Services</label>
                    </li>
                  </ul>
              </div>
            </div>

            <div className="columns">
                <div className="column">
                <label className="checkbox">
                  <input type="checkbox" required/>I self-certify that my business will
                  strive to achieve the following:
                  <ul>
                    <li>
                      <b>Masks</b> - Masks worn by all employees.
                    </li>
                    <li>
                      <b>Hygiene</b> - Hand and surface hygiene practiced by ALL
                      employees in conjunction with physical distancing when
                      possible.
                    </li>
                    <li>
                      <b>Health</b> - Employee attesting daily- no primary COVID-19
                      symptoms.
                      <br />
                    </li>
                  </ul>
                </label>
              </div>
          </div>

          <div className="columns">
              <div className="column">
                  <strong>Are you also 'customer-conscious'?</strong>
                  <ul className="list-style-none">
                    <li>
                      <input type="radio" value="Yes! - Our patrons are required to wear masks" name="CUSTMRCONS" id="mce-CUSTMRCONS-0" />
                        <label for="mce-CUSTMRCONS-0">Yes! - Our patrons are required to wear masks</label>
                      </li>
                      <li>
                        <input type="radio" value="No" name="CUSTMRCONS" id="mce-CUSTMRCONS-1" />
                          <label for="mce-CUSTMRCONS-1">No</label>
                        </li>
                  </ul>
              </div>
          </div>

          <div className="columns">
            <div className="column">
                <strong>Additional Info for Customers (Optional)</strong>
                  <label className="label sr-only" for="mce-BIZEXPLAIN">Additional Info for Customers (Optional) {' '}</label>
                  <textarea
                    type="textarea"
                    defaultValue=""
                    name="BIZEXPLAIN"
                    className="input textarea"
                    id="mce-BIZEXPLAIN"
                    placeholder="An optional field to explain more about your 'Covid-Conscious' business practices. Providing hand sanitizer or masks? Reservations required? Let them know here!"
                    rows="5"
                    cols="10"
                    maxlength="400"
                  />
                <small>400 character limit</small>
            </div>
          </div>

            <label className="checkbox">
              <input type="checkbox" required />I agree to the{' '}
              <a href="/terms-and-conditions">terms and conditions</a>
            </label>

            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: 'none' }}
              />
              <div
                className="response"
                id="mce-success-response"
                style={{ display: 'none' }}
              />
            </div>
            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
            <div
              style={{ position: 'absolute', left: '-5000px' }}
              aria-hidden="true"
            >
              <div className="clear"></div>
            </div>
            <button
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              onClick={() => {
                const form = formElRef.current.querySelector('form');
                var isValidForm = form.checkValidity();
                if (!isValidForm){ return; }
                setTimeout(() => {
                  navigate('/toolkit/');
                }, 500);
              }}
              className="button center-button"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default MailChimpFormEmbedded
