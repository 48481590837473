import React from 'react'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'
import PageHelmet from '../components/pageHelmet'

// import SignUp from '../components/signup'
import MailchimpFormEmbedded from '../components/mailchimpFormEmbedded'
import forBizHeroImg from '../images/hero-driver.jpg'
import forBizSvg from '../images/icon-certify.svg'

import iconOne from '../images/icon-one.svg'
import iconTwo from '../images/icon-two.svg'
import iconThree from '../images/icon-three.svg'

const GetStartedPage = () => (
  <Layout>
    <PageHelmet
      title="Get Started"
      description="Sign up as a COVID-Conscious business"
      image={forBizHeroImg} />
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'GET'}
      accentedTitle={'Started'}
      subtitle={'Sign up as a COVID-Conscious business'}
    />

    <section className="section" key="mow-getstarted-1">
      <div className="columns has-text-centered">
        <div className="column is-6 is-offset-3">
          {/*<h3 className="title is-3">How to get started</h3>*/}
        </div>
      </div>

      <div className="container">
        <div className="columns">
          <div className="column">
            <img src={iconOne} className="start-step" alt="step" />
            <h5 className="has-text-centered">Self-Certify</h5>
            <div className="content has-text-justified">
              and SIGN UP as a COVID conscious business. ‘Mask of Wellness’ certification is provided to Covid-Conscious businesses that supports three simple measures:
              <ol>
                <li>Masks worn by all employees</li>
                <li>Personal/Surface/ Distance Hygiene</li>
                <li>Daily health self-certification</li>
              </ol>
            </div>
          </div>
          <div className="column">
            <img src={iconTwo} className="start-step" alt="step" />
            <h5 className="has-text-centered">PROVIDE RESOURCES</h5>
            <div className="content has-text-justified">
              to your employees for making masks. We will be adding links to
              order custom masks for your business, non- custom masks, or
              methods to easily make masks out of materials in your own home.
              Attached is a CDC guide on homemade cloth face coverings to get
              started find more resources on our <a href="/faq/">FAQ</a>.
            </div>
          </div>
          <div className="column">
            <img src={iconThree} className="start-step" alt="step" />
            <h5 className="has-text-centered">PROMOTE YOUR BUSINESS</h5>
            <div className="content has-text-justified">
              with ‘Mask of Wellness’ window clings, stickers, on your website
              and through social media. Once certified, you receive the
              downloadable kit of graphics and content to use digitally and at
              your storefront. Spread the message for positive change. We’re in
              this together for all of OUR communities.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section" key="mow-getstarted-signup">
      <div className="container">
        <div className="columns">
          <div className="column is-half is-offset-3">
            <div className="box">
              <MailchimpFormEmbedded />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default GetStartedPage
