export const mailchimpCountries = [
  { id: "164", name: "USA" },
  { id: "286", name: "Aaland Islands" },
  { id: "274", name: "Afghanistan" },
  { id: "2", name: "Albania" },
  { id: "3", name: "Algeria" },
  { id: "178", name: "American Samoa" },
  { id: "4", name: "Andorra" },
  { id: "5", name: "Angola" },
  { id: "176", name: "Anguilla" },
  { id: "175", name: "Antigua And Barbuda" },
  { id: "6", name: "Argentina" },
  { id: "7", name: "Armenia" },
  { id: "179", name: "Aruba" },
  { id: "8", name: "Australia" },
  { id: "9", name: "Austria" },
  { id: "10", name: "Azerbaijan" },
  { id: "11", name: "Bahamas" },
  { id: "12", name: "Bahrain" },
  { id: "13", name: "Bangladesh" },
  { id: "14", name: "Barbados" },
  { id: "15", name: "Belarus" },
  { id: "16", name: "Belgium" },
  { id: "17", name: "Belize" },
  { id: "18", name: "Benin" },
  { id: "19", name: "Bermuda" },
  { id: "20", name: "Bhutan" },
  { id: "21", name: "Bolivia" },
  { id: "325", name: "Bonaire, Saint Eustatius and Saba" },
  { id: "22", name: "Bosnia and Herzegovina" },
  { id: "23", name: "Botswana" },
  { id: "181", name: "Bouvet Island" },
  { id: "24", name: "Brazil" },
  { id: "180", name: "Brunei Darussalam" },
  { id: "25", name: "Bulgaria" },
  { id: "26", name: "Burkina Faso" },
  { id: "27", name: "Burundi" },
  { id: "28", name: "Cambodia" },
  { id: "29", name: "Cameroon" },
  { id: "30", name: "Canada" },
  { id: "31", name: "Cape Verde" },
  { id: "32", name: "Cayman Islands" },
  { id: "33", name: "Central African Republic" },
  { id: "34", name: "Chad" },
  { id: "35", name: "Chile" },
  { id: "36", name: "China" },
  { id: "185", name: "Christmas Island" },
  { id: "37", name: "Colombia" },
  { id: "204", name: "Comoros" },
  { id: "38", name: "Congo" },
  { id: "183", name: "Cook Islands" },
  { id: "268", name: "Costa Rica" },
  { id: "275", name: "Cote D'Ivoire" },
  { id: "40", name: "Croatia" },
  { id: "276", name: "Cuba" },
  { id: "298", name: "Curacao" },
  { id: "41", name: "Cyprus" },
  { id: "42", name: "Czech Republic" },
  { id: "318", name: "Democratic Republic of the Congo" },
  { id: "43", name: "Denmark" },
  { id: "44", name: "Djibouti" },
  { id: "289", name: "Dominica" },
  { id: "187", name: "Dominican Republic" },
  { id: "45", name: "Ecuador" },
  { id: "46", name: "Egypt" },
  { id: "47", name: "El Salvador" },
  { id: "48", name: "Equatorial Guinea" },
  { id: "49", name: "Eritrea" },
  { id: "50", name: "Estonia" },
  { id: "51", name: "Ethiopia" },
  { id: "189", name: "Falkland Islands" },
  { id: "191", name: "Faroe Islands" },
  { id: "52", name: "Fiji" },
  { id: "53", name: "Finland" },
  { id: "54", name: "France" },
  { id: "193", name: "French Guiana" },
  { id: "277", name: "French Polynesia" },
  { id: "56", name: "Gabon" },
  { id: "57", name: "Gambia" },
  { id: "58", name: "Georgia" },
  { id: "59", name: "Germany" },
  { id: "60", name: "Ghana" },
  { id: "194", name: "Gibraltar" },
  { id: "61", name: "Greece" },
  { id: "195", name: "Greenland" },
  { id: "192", name: "Grenada" },
  { id: "196", name: "Guadeloupe" },
  { id: "62", name: "Guam" },
  { id: "198", name: "Guatemala" },
  { id: "270", name: "Guernsey" },
  { id: "63", name: "Guinea" },
  { id: "65", name: "Guyana" },
  { id: "200", name: "Haiti" },
  { id: "66", name: "Honduras" },
  { id: "67", name: "Hong Kong" },
  { id: "68", name: "Hungary" },
  { id: "69", name: "Iceland" },
  { id: "70", name: "India" },
  { id: "71", name: "Indonesia" },
  { id: "278", name: "Iran" },
  { id: "279", name: "Iraq" },
  { id: "74", name: "Ireland" },
  { id: "323", name: "Isle of Man" },
  { id: "75", name: "Israel" },
  { id: "76", name: "Italy" },
  { id: "202", name: "Jamaica" },
  { id: "78", name: "Japan" },
  { id: "288", name: "Jersey  (Channel Islands)" },
  { id: "79", name: "Jordan" },
  { id: "80", name: "Kazakhstan" },
  { id: "81", name: "Kenya" },
  { id: "203", name: "Kiribati" },
  { id: "82", name: "Kuwait" },
  { id: "83", name: "Kyrgyzstan" },
  { id: "84", name: "Lao People's Democratic Republic" },
  { id: "85", name: "Latvia" },
  { id: "86", name: "Lebanon" },
  { id: "87", name: "Lesotho" },
  { id: "88", name: "Liberia" },
  { id: "281", name: "Libya" },
  { id: "90", name: "Liechtenstein" },
  { id: "91", name: "Lithuania" },
  { id: "92", name: "Luxembourg" },
  { id: "208", name: "Macau" },
  { id: "93", name: "Macedonia" },
  { id: "94", name: "Madagascar" },
  { id: "95", name: "Malawi" },
  { id: "96", name: "Malaysia" },
  { id: "97", name: "Maldives" },
  { id: "98", name: "Mali" },
  { id: "99", name: "Malta" },
  { id: "207", name: "Marshall Islands" },
  { id: "210", name: "Martinique" },
  { id: "100", name: "Mauritania" },
  { id: "212", name: "Mauritius" },
  { id: "241", name: "Mayotte" },
  { id: "101", name: "Mexico" },
  { id: "102", name: "Moldova, Republic of" },
  { id: "103", name: "Monaco" },
  { id: "104", name: "Mongolia" },
  { id: "290", name: "Montenegro" },
  { id: "294", name: "Montserrat" },
  { id: "105", name: "Morocco" },
  { id: "106", name: "Mozambique" },
  { id: "242", name: "Myanmar" },
  { id: "107", name: "Namibia" },
  { id: "108", name: "Nepal" },
  { id: "109", name: "Netherlands" },
  { id: "110", name: "Netherlands Antilles" },
  { id: "213", name: "New Caledonia" },
  { id: "111", name: "New Zealand" },
  { id: "112", name: "Nicaragua" },
  { id: "113", name: "Niger" },
  { id: "114", name: "Nigeria" },
  { id: "217", name: "Niue" },
  { id: "214", name: "Norfolk Island" },
  { id: "272", name: "North Korea" },
  { id: "116", name: "Norway" },
  { id: "117", name: "Oman" },
  { id: "118", name: "Pakistan" },
  { id: "222", name: "Palau" },
  { id: "282", name: "Palestine" },
  { id: "119", name: "Panama" },
  { id: "219", name: "Papua New Guinea" },
  { id: "120", name: "Paraguay" },
  { id: "121", name: "Peru" },
  { id: "122", name: "Philippines" },
  { id: "221", name: "Pitcairn" },
  { id: "123", name: "Poland" },
  { id: "124", name: "Portugal" },
  { id: "126", name: "Qatar" },
  { id: "315", name: "Republic of Kosovo" },
  { id: "127", name: "Reunion" },
  { id: "128", name: "Romania" },
  { id: "129", name: "Russia" },
  { id: "130", name: "Rwanda" },
  { id: "205", name: "Saint Kitts and Nevis" },
  { id: "206", name: "Saint Lucia" },
  { id: "324", name: "Saint Martin" },
  { id: "237", name: "Saint Vincent and the Grenadines" },
  { id: "132", name: "Samoa (Independent)" },
  { id: "227", name: "San Marino" },
  { id: "255", name: "Sao Tome and Principe" },
  { id: "133", name: "Saudi Arabia" },
  { id: "134", name: "Senegal" },
  { id: "326", name: "Serbia" },
  { id: "135", name: "Seychelles" },
  { id: "136", name: "Sierra Leone" },
  { id: "137", name: "Singapore" },
  { id: "302", name: "Sint Maarten" },
  { id: "138", name: "Slovakia" },
  { id: "139", name: "Slovenia" },
  { id: "223", name: "Solomon Islands" },
  { id: "140", name: "Somalia" },
  { id: "141", name: "South Africa" },
  { id: "257", name: "South Georgia and the South Sandwich Islands" },
  { id: "142", name: "South Korea" },
  { id: "311", name: "South Sudan" },
  { id: "143", name: "Spain" },
  { id: "144", name: "Sri Lanka" },
  { id: "293", name: "Sudan" },
  { id: "146", name: "Suriname" },
  { id: "225", name: "Svalbard and Jan Mayen Islands" },
  { id: "147", name: "Swaziland" },
  { id: "148", name: "Sweden" },
  { id: "149", name: "Switzerland" },
  { id: "285", name: "Syria" },
  { id: "152", name: "Taiwan" },
  { id: "260", name: "Tajikistan" },
  { id: "153", name: "Tanzania" },
  { id: "154", name: "Thailand" },
  { id: "233", name: "Timor-Leste" },
  { id: "155", name: "Togo" },
  { id: "232", name: "Tonga" },
  { id: "234", name: "Trinidad and Tobago" },
  { id: "156", name: "Tunisia" },
  { id: "157", name: "Turkey" },
  { id: "158", name: "Turkmenistan" },
  { id: "287", name: "Turks &amp; Caicos Islands" },
  { id: "159", name: "Uganda" },
  { id: "161", name: "Ukraine" },
  { id: "162", name: "United Arab Emirates" },
  { id: "262", name: "United Kingdom" },
  { id: "163", name: "Uruguay" },
  { id: "165", name: "Uzbekistan" },
  { id: "239", name: "Vanuatu" },
  { id: "166", name: "Vatican City State (Holy See)" },
  { id: "167", name: "Venezuela" },
  { id: "168", name: "Vietnam" },
  { id: "169", name: "Virgin Islands (British)" },
  { id: "238", name: "Virgin Islands (U.S.)" },
  { id: "188", name: "Western Sahara" },
  { id: "170", name: "Yemen" },
  { id: "173", name: "Zambia" },
  { id: "174", name: "Zimbabwe" },
];

export const mailchimpBusinessTypes = [
  { id: "Advertising/Marketing" },
  { id: "Agriculture and food processing" },
  { id: "Artist" },
  { id: "Auto-repair" },
  { id: "Banks" },
  { id: "Big Box Stores" },
  { id: "Business services" },
  { id: "Chamber of Commerce" },
  { id: "Cleaning Services" },
  { id: "Coffee Shops" },
  { id: "Construction/Repair" },
  { id: "Credit Union" },
  { id: "Daycare centers" },
  { id: "Dentist" },
  { id: "Delivery" },
  { id: "Distribution" },
  { id: "Educational institutions" },
  { id: "Entertainment" },
  { id: "Farmers' markets" },
  { id: "Fast Food Restaurant" },
  { id: "Finance/Technology" },
  { id: "Food Bank" },
  { id: "Footwear Store" },
  { id: "Funeral Services" },
  { id: "Gas Station/Convenience Stores" },
  { id: "Graphic Designer" },
  { id: "Gym" },
  { id: "Government" },
  { id: "Hardware stores" },
  { id: "Healthcare" },
  { id: "Home Improvement" },
  { id: "Hotels/Hospitality" },
  { id: "Hospital" },
  { id: "Ice Cream Shop" },
  { id: "Insurance Company" },
  { id: "Investors" },
  { id: "IT Services" },
  { id: "Lawyer/Law Firm" },
  { id: "Liquor Store" },
  { id: "Manufacturing" },
  { id: "Marketing Agency" },
  { id: "Marijuana Dispensary" },
  { id: "Media/News Company" },
  { id: "Nonprofit Organization" },
  { id: "Museum" },
  { id: "Optician" },
  { id: "Optometrist" },
  { id: "Other" },
  { id: "Outdoor and Sporting Goods" },
  { id: "Pharmacies" },
  { id: "Photography" },
  { id: "Post offices and shipping businesses" },
  { id: "Physical Fitness" },
  { id: "Print Shop" },
  { id: "Public Services" },
  { id: "Recreation Center" },
  { id: "Restaurant/Bar" },
  { id: "Real Estate" },
  { id: "Religion and Spirituality" },
  { id: "Retail Store" },
  { id: "Salon/Personal Care" },
  { id: "Social services" },
  { id: "Software Developers" },
  { id: "Storage" },
  { id: "Supermarket/Grocery Store" },
  { id: "Suppliers" },
  { id: "Transportation" },
  { id: "Tattoo Shop" },
  { id: "Veterinary clinics and pet stores" },
  { id: "Warehousing" },
  { id: "Yoga Studio" },
  { id: "Youth Organization" },
  { id: "Zoo" },
];
